import React, { useCallback, useMemo, useState } from 'react';
import { Input, Label } from '@/components/basic';
import { ConfirmDialog } from '../basic/ConfirmDialog/ConfirmDialog';

type RecurringOptions = 'this' | 'future';

export interface Props {
  open: boolean;
  onClose: () => void;
  confirmFunction: (recurring: RecurringOptions) => void;
}

export function ReservationRecurringEditConfirm({
  onClose,
  open,
  confirmFunction
}: Props) {
  const [recurringValue, setRecurringValue] =
    useState<RecurringOptions>('this');
  const onCloseBind = useCallback(() => {
    setRecurringValue('this');
    onClose();
  }, [setRecurringValue, onClose]);

  const options: { value: RecurringOptions; label: string }[] = useMemo(
    () => [
      { label: 'Only this reservation', value: 'this' },
      { label: 'This and all the future reservations', value: 'future' }
      //{ label: 'All the reservations', value: 'all' }
    ],
    []
  );

  return (
    <ConfirmDialog
      title={'Edit Recurring Reservation'}
      open={open}
      onConfirm={() => {
        confirmFunction(recurringValue);
      }}
      onClose={onCloseBind}
      type="confirm"
    >
      <div className="mt-2 flex flex-col space-y-4 text-sm">
        {options.map((option) => (
          <Label key={option.value} radio className="block">
            <Input
              type="radio"
              onChange={() => {
                setRecurringValue(option.value);
              }}
              checked={option.value === recurringValue}
            />
            <span className="ml-2">{option.label}</span>
          </Label>
        ))}
      </div>
    </ConfirmDialog>
  );
}
